import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import tw from 'twin.macro';

import Layout from '~components/Layout';
import SEO from '~components/SEO';

import { BecomeABroker, FindABroker } from '~components/Dropdown';
import Embed from '~components/Embed';
import Heading from '~components/Heading';
import Logo from '~components/Logo';
import Tabs from '~components/Tabs/Tabs';

const IndexPage: React.FC = () => {
  const fsbgImg = tw`absolute inset-0 z-0`;

  return (
    <Layout>
      <SEO />
      <div tw="relative z-0 sm:(py-6) xl:(py-12) xl:(px-40)">
        <div tw="container relative z-10">
          <div tw="flex items-end justify-between space-x-3 py-6 mb-6">
            <a href="https://ibac.ca">
              <Logo tw="w-auto h-20" />
            </a>
            <a
              href="https://www.enfinlecalme.ca/"
              tw="rounded py-3 px-4 bg-primary text-white text-sm font-bold uppercase hover:(bg-dark) focus:(outline-none ring-4 ring-primary ring-opacity-60)"
            >
              Français
            </a>
          </div>
          <div tw="grid gap-3 md:(gap-2) lg:(grid-cols-2 gap-6) xl:(gap-12) 2xl:(gap-32)">
            <div>
              <Heading
                level="h1"
                tw="mt-0 uppercase text-primary font-bold lg:(text-6xl) xl:(text-7xl) 2xl:(text-8xl)"
              >
                <span tw="text-secondary">The</span> Broker Benefit
              </Heading>
              <p tw="text-dark my-4 font-bold text-xl 2xl:(text-2xl)">
              The world doesn’t work without insurance, and insurance works best with an insurance broker. Talk to an insurance broker and get the benefit of expert, unbiased advice. Make sure you get the right coverage for your home, auto, or business.
              </p>
            </div>
            <div tw="pb-6">
              <p tw="mt-0 uppercase text-primary font-bold text-lg lg:(text-xl) xl:(text-2xl)">
                Watch Our Ad
              </p>
              <Embed
                // title="The Broker Benefit | Insurance Brokers Association of Canada"
                title="Works Best - 30"
                src="https://www.youtube-nocookie.com/embed/LXmloXvYEik"
              />
            </div>
          </div>
        </div>
        <StaticImage src="../images/bg.png" alt="" style={fsbgImg} />
      </div>
      <div tw="mb-12 py-6 bg-primary text-white z-30 xl:(py-8 px-40)">
        <div tw="container">
          <div tw="flex flex-col space-y-6 justify-between lg:(flex-row space-y-0 space-x-48) xl:(items-center)">
            <div>
              <p tw="text-white uppercase font-bold text-xl md:(text-2xl) lg:(text-2xl)">
                To find a broker where you live, please visit one of our Member Association
                websites:
              </p>
            </div>
            <div tw="flex-shrink-0">
              <FindABroker />
            </div>
          </div>
        </div>
      </div>
      <div tw="xl:(px-40)">
        <div tw="container">
          <Tabs />
        </div>
      </div>
      <div tw="mb-12 py-6 bg-primary text-white z-30 xl:(py-8 px-40)">
        <div tw="container">
          <div tw="flex flex-col space-y-6 xl:(items-center) lg:(flex-row space-y-0 space-x-48)">
            <div>
              <p tw="text-white uppercase font-bold text-xl md:(text-2xl) lg:(text-2xl)">
                If you're interested in a career as an Insurance Broker, please visit one of our
                Member Association websites:
              </p>
            </div>
            <div tw="flex-shrink-0">
              <BecomeABroker />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
