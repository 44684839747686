import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Tabs as ReachTabs, TabList, TabPanels, TabPanel } from '@reach/tabs';
import tw from 'twin.macro';

import TabButton from './TabButton';

const Tabs = () => {
  const tabImageStyle = tw`h-auto w-full object-cover`;

  return (
    <ReachTabs tw="my-12 grid! gap-12 md:(grid-cols-2) 2xl:(grid-cols-3)">
      <div tw="2xl:(col-span-2)">
        <p tw="text-primary mb-4 uppercase font-bold text-2xl lg:(text-3xl)">
          Brokers compare a range of Canada’s best insurance companies to find you the right
          coverage at a competitive price.
        </p>
        <TabList tw="flex flex-col space-y-4 bg-transparent">
          <TabButton title="Custom Advice">
            Brokers are licensed professionals who will advise you on your personal insurance needs
            with expertise.
          </TabButton>
          <TabButton title="Trust">
            A broker's advice is impartial, so it’s always in your best interests.
          </TabButton>
          <TabButton title="Advocacy">
            In the event of a claim your broker ensures you receive fair and prompt resolution.
          </TabButton>
          <TabButton title="Knowledge">
            Brokers are experts at risk assessment and their professional education is ongoing.
          </TabButton>
          <TabButton title="Innovation">
            Insurance brokers are well educated on the very latest developments and use a variety of
            technologies to serve and communicate with their clients.
          </TabButton>
        </TabList>
      </div>
      <TabPanels tw="max-h-full 2xl:(col-span-1)">
        <TabPanel>
          <StaticImage
            style={tabImageStyle}
            height={800}
            src="../../images/couple-on-laptop.jpg"
            alt=""
            layout="constrained"
          />
        </TabPanel>
        <TabPanel>
          <StaticImage
            style={tabImageStyle}
            height={800}
            src="../../images/man-on-phone.jpg"
            alt=""
            layout="constrained"
          />
        </TabPanel>
        <TabPanel>
          <StaticImage
            style={tabImageStyle}
            height={800}
            src="../../images/woman-on-phone.jpg"
            alt=""
            layout="constrained"
          />
        </TabPanel>
        <TabPanel>
          <StaticImage
            style={tabImageStyle}
            height={800}
            src="../../images/man-taking-notes.jpg"
            alt=""
            layout="constrained"
          />
        </TabPanel>
        <TabPanel>
          <StaticImage
            style={tabImageStyle}
            height={800}
            src="../../images/woman-wearing-headset.jpg"
            alt=""
            layout="constrained"
          />
        </TabPanel>
      </TabPanels>
    </ReachTabs>
  );
};

export default Tabs;
