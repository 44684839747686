import React from 'react';
import 'twin.macro';

interface EmbedProps {
  src: string;
  title: string;
}

const Embed: React.FC<EmbedProps> = ({ src, title }) => (
  <div tw="max-w-3xl">
    <div tw="relative w-full rounded-xl overflow-hidden" style={{ paddingTop: '56.25%' }}>
      <iframe
        title={title}
        tw="absolute inset-0 w-full h-full"
        src={`${src}?rel=0`}
        frameBorder="0"
        allowFullScreen
      />
    </div>
  </div>
);

export default Embed;
