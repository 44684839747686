import React from 'react';
import 'twin.macro';
import { MenuLink } from '@reach/menu-button';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

interface DropdownLinkProps {
  href: string;
}

const DropdownLink: React.FC<DropdownLinkProps> = ({ href, children }) => {
  // const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
  //   e.preventDefault();
  //   const { href } = e.currentTarget;
  //   if (typeof window != undefined) {
      //   if (window.gtag) {
      //     window.gtag('event', 'conversion', {
      //       send_to: 'AW-837012328/2lkPCMvThNoCEOiWj48D',
      //       event_callback: () => {
      //         if (href) {
      //           window.location = href;
      //         }
      //       }
      //     });

  //     window.location.href = href;
  //   }
  //   return false;
  // };

  return (
    <OutboundLink href={href}>   
     <MenuLink  tw="font-bold text-lg py-2 px-3 hover:(bg-primary text-white) focus:(ring ring-primary ring-opacity-60)">
      {children}
    </MenuLink>
    </OutboundLink>
  );
};

export default DropdownLink;
