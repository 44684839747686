import React from 'react';
import tw from 'twin.macro';
import { StaticImage } from 'gatsby-plugin-image';

import Logo from '~components/Logo';
import Twitter from '~components/Twitter';

import useSiteMetaData from '~hooks/useSiteMetaData';

const Footer: React.FC = () => {
  const { siteMetadata } = useSiteMetaData();
  const fsbgImg = tw`absolute inset-0`;

  return (
    <footer tw="relative py-2 mt-auto z-0 lg:(px-40)">
      <div tw="container relative z-20">
        <div tw="grid gap-6 px-2 lg:(grid-cols-2 items-end text-left)">
          <div tw="flex mb-5 lg:(order-2 justify-end)">
            <Logo tw="h-20 w-auto" />
          </div>
          <div tw="lg:(order-1)">
            <Twitter tw="h-8 w-auto" />
            <p tw="my-4 font-bold text-secondary">
              Insurance Brokers Association of Canada
              <br />
              Exchange Tower
              <br />
              130 King Street West, Suite 2125, PO Box 443
              <br />
              Toronto, Ontario M5X 1E4
            </p>
            <p tw="text-sm font-bold text-gray-700 my-0">
              &copy;{new Date().getFullYear()} {siteMetadata.defaultTitle}. Website by{' '}
              <a
                tw="font-medium hover:(underline)"
                href="https://www.agency59.ca"
                target="_blank"
                rel="noopener noreferrer"
              >
                Agency59
              </a>
              .
            </p>
          </div>
        </div>
      </div>
      <StaticImage src="../../images/bg.png" alt="" style={fsbgImg} />
    </footer>
  );
};

export default Footer;
