import { ChevronDownIcon } from '@heroicons/react/solid';
import { Menu, MenuButton, MenuList } from '@reach/menu-button';
import React from 'react';
import 'twin.macro';
import DropdownLink from './DropdownLink';

const Dropdown: React.FC = () => (
  <Menu tw="relative z-50">
    <MenuButton tw="flex items-center space-x-2 rounded py-3 px-6 bg-gray-50 text-primary font-bold text-xl uppercase z-50 hover:(bg-dark text-gray-50) focus:(outline-none ring-4 ring-primary ring-opacity-60)">
      <span>Find a Broker</span>
      <ChevronDownIcon tw="h-6 w-6" />
    </MenuButton>
    <MenuList tw="flex flex-col rounded bg-white shadow-xl mt-1 py-2 border-secondary z-50">
      <DropdownLink href="https://ibabc.org/FindBroker">British Columbia</DropdownLink>
      <DropdownLink href="https://ibaa.ca/consumers/find-broker.html">Alberta</DropdownLink>
      <DropdownLink href="https://www.ibas.ca/cgi/page.cgi/find-a-broker.html">
        Saskatchewan
      </DropdownLink>
      <DropdownLink href="https://ontariobrokers.ca/">Ontario</DropdownLink>
      <DropdownLink href="https://www.rccaq.com/trouver_courtier_en.html">Quebec</DropdownLink>
      <DropdownLink href="https://www.nbinsurancebrokers.ca/find-a-broker.html">
        New Brunswick
      </DropdownLink>
      <DropdownLink href="https://www.ibans.com/brokersmap.html">Nova Scotia</DropdownLink>
      <DropdownLink href="https://ibapei.wordpress.com/locations/">
        Prince Edward Island
      </DropdownLink>
      <DropdownLink href="https://www.iban.ca/brokersmap.html">
        Newfoundland &amp; Labrador
      </DropdownLink>
      <DropdownLink href="https://ibaa.ca/consumers/find-broker.html">Yukon</DropdownLink>
      <DropdownLink href="https://ibaa.ca/consumers/find-broker.html">
        Northwest Territories
      </DropdownLink>
      <DropdownLink href="https://ibaa.ca/consumers/find-broker.html">Nunavut</DropdownLink>
    </MenuList>
  </Menu>
);

export default Dropdown;
